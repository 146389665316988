<template>
  <b-container fluid>
    <h4>Notificação</h4>
    <b-row>
      <b-col>
        <b-form @submit="submitForm">
          <form-input
            v-model="form.titulo"
            label="Título:"
            placeholder="Informe um título."
            icon="card-heading"
            required
          />

          <form-textarea
            v-model="form.mensagem"
            :state="form.mensagem.length <= 600"
            label="Mensagem:"
            placeholder="Descreva uma mensagem com o máximo de 600 caracteres."
            rows="10"
            icon="card-text"
            no-resize
            required
          />

          <b-row>
            <b-col>
              <b-form-group label="Data para Expirar:">
                <b-form-datepicker
                  v-model="form.data_expira"
                  placeholder="Informe uma data."
                  required
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Horário para Expirar:">
                <b-form-timepicker
                  v-model="form.hora_expira"
                  placeholder="Informe a hora."
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="primary">SALVAR</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";
export default {
  components: { FormInput, FormTextarea },
  name: "detailsAlert",
  data() {
    return {
      form: { ...this.$store.state.alert.alert },
    };
  },
  methods: {
    ...mapActions("alert", ["createAlert", "updateAlert", "getOneAlert"]),
    ...mapMutations(["setToasted"]),

    resetForm() {
      this.form = {
        id: "",
        titulo: "",
        mensagem: "",
        data_expira: "",
        hora_expira: "",
      };
    },
    async submitForm(event) {
      event.preventDefault();
      const { form } = this;

      const formatDateTime = {
        ...form,
        data_expira: form.data_expira + " " + form.hora_expira,
      };

      if (form.id) {
        this.updateAlert(formatDateTime);
      } else {
        this.createAlert(formatDateTime);
      }
    },
  },
  async created() {
    const { id } = this.$route.params;

    if (id && !this.form.id) {
      await this.getOneAlert(id);
      this.form = { ...this.$store.state.alert.alert };
    }
  },
};
</script>

<style></style>
